import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "modern-and-applicative-javascript---part-2"
    }}>{`Modern and applicative JavaScript - Part 2`}</h1>
    <h1 {...{
      "id": "basics-of-typescript"
    }}>{`Basics of TypeScript`}</h1>
    <h5 {...{
      "id": "july-12-2019--aptitude-softwares-office"
    }}>{`July 12, 2019 | `}<a parentName="h5" {...{
        "href": "http://twitter.com/AptitudeSW"
      }}>{`Aptitude Software's`}</a>{` office`}</h5>
    <hr></hr>
    <ul>
      <li parentName="ul">{`📊 `}<strong parentName="li">{`Slides`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://slides.com/kajetansw/basics-of-typescript"
        }}>{`https://slides.com/kajetansw/basics-of-typescript`}</a></li>
      <li parentName="ul">{`⌚ `}<strong parentName="li">{`Duration`}</strong>{`: ~90 minutes`}</li>
      <li parentName="ul">{`👨‍💻 `}<strong parentName="li">{`Form`}</strong>{`: workshops`}</li>
    </ul>
    <hr></hr>
    <p>{`This was a second part of workshops I hosted where we worked on implementing "Splitter" web application together. `}</p>
    <p>{`I took this opportunity to introduce TypeScript and talk about why it can make our lives easier. Then we migrated our existing REST API implementation to TypeScript as a part of the step-by-step live coding session.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "outline"
    }}>{`Outline`}</h3>
    <ul>
      <li parentName="ul">{`A little background on TypeScript`}</li>
      <li parentName="ul">{`TypeScript and its role of a superset`}</li>
      <li parentName="ul">{`How does it work behind a scenes`}</li>
      <li parentName="ul">{`TypeScript-specific features`}</li>
      <li parentName="ul">{`Benefits and flaws of adopting TS`}</li>
      <li parentName="ul">{`👨‍💻 CODING TIME 👨‍💻`}</li>
      <li parentName="ul">{`Alternative for express.js + TypeScript `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      